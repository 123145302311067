.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.formContainer {
  padding: 40px;
  background: #fff;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 992px) {
  .header .header-top {
    height: 100px;
  }
}

@media (min-width: 1400px) {
  .header .header-top {
    height: 70px;
  }
}

header li.nav-item a {
  height: 100%;
}

.table th,
.table td {
  vertical-align: top;
}

.table thead tr th {
  vertical-align: top;
}

.table-grid .table td:nth-child(1),
.table-grid .table th:nth-child(1) {
  display: none;
}

.MuiTable-root thead td:nth-child(1),
.MuiTable-root thead th:nth-child(1),
.MuiTable-root tbody td:nth-child(1),
.MuiTable-root tbody th:nth-child(1) {
  display: none;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3f4254;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #e4e6ef;
  border-radius: 0.42rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border: 1px solid #e4e6ef;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  max-height: 100px;
  overflow-y: auto;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.box-shadow-border {
  -webkit-box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
  box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
  border: 0;

  border-radius: 0.42rem;
}

thead.MuiTableHead-root tr:nth-child(1) th {
  vertical-align: text-top;
}

body main form .form-inline .form-control {
  width: 100%;
}

html body .MuiTableCell-root {
  padding: 10px;
}

html body main .MuiPaper-elevation1 {
  box-shadow: unset;
}

html body .MuiCheckbox-colorPrimary.Mui-checked {
  color: #187de4;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

html body .react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 350px;
  border: 1px solid #e4e6ef;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 2;
}

.input-dollar-symbol {
  position: relative;
}

.input-dollar-symbol input {
  padding-left: 18px;
}

.input-dollar-symbol:before {
  position: absolute;
  top: 0;
  content: '$';
}

.input-dollar-symbol:before {
  left: 5px;
  top: 9px;
}

.name-link {
  font-size: 1.1em;
  font-weight: bold;
  text-decoration: underline;
}
html body .form-group label,
html body label {
  font-weight: 600;
}

.tableFinancial .MuiTableCell-root {
  font-size: 14px;
}

.tableFinancial tr td:nth-child(3) {
  text-align: right;
}

.panel-content {
  width: 500px;
}

.btn-sm-sm {
  padding: 0.55rem 0.25rem;
}
